<script setup lang="ts">
import {isAddress} from 'viem';
import {useRouter} from "#imports";

const props = defineProps<{
    title?: string,
}>();

const router = useRouter();

function openScanner() {
    window.Telegram.WebApp.showScanQrPopup({}, (result: string) => {
        console.log("QR result:", result);
        if (isAddress(result)) {
            window.Telegram.WebApp.closeScanQrPopup();
            router.push(`/wallet/send?address=${result}`);
        }
    });
}
</script>

<template>
    <div class="header u-flex u-flex--justify-between">
        <!--<nuxt-link to="/wallet" v-haptic-feedback>
            <img class="u-image" src="/img/icon-gem.svg" alt="Home" width="24" height="24">
        </nuxt-link>-->
        <div></div>

        <h1 class="u-fw-600 u-text-18" v-if="props.title">{{ props.title }}</h1>

        <button type="button" class="u-semantic-button" @click="openScanner()" v-haptic-feedback>
            <img class="u-image" src="/img/icon-qr-scan.svg" alt="Scan QR" width="24" height="24">
        </button>
    </div>
</template>
